import React ,{useContext, useEffect} from 'react'
import useForm from "./useForm";
import validate from "./validateInfo";
import {SuccessAlert} from '../SuccessAlert';
import { If } from 'react-if';
import {Context} from "../Store"
// import DashboardManage from '../../Utility/DashboardManage';

const loginWrapper = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#eee",
  marginBottom: "20px",
};

const signin_form = {
  width: "100%",
  maxWidth: "450px",
  backgroundColor: "#fff",
  padding: "50px",
  borderRadius: "5px",
};
const signin_form_logo = {
  textAlign: "center",
  textTransform: "capitalize",
  marginBottom: "30px",
};
const signin_form_label = {
  textTransform: "capitalize",
};
const login_btn = {
  width: "100%",
  backgroundColor: "green",
  border: "none",
  outline: "none",
  height: "40px",
  borderRadius: "5px",
  textTransform: "capitalize",
  color: "#fff",
};
const error = {
  color: "red",
};

export const Login = () => {
  const [isMessage , setIsMessage] = React.useState(false);
  const [message, setMessage] = React.useState()
  const { handleChange, formvalues, handleSubmit, errors } = useForm(validate);
  const state = useContext(Context);
  
    useEffect(() => {    
     setIsMessage(state[0]['message']['Ismessage']);
     setMessage (state[0]['message']['data']['message']);
    }, [state]); 
    
    useEffect(() => {
      setIsMessage(state[0]['message']['Ismessage']);
    },[state[0]['message']['Ismessage']])
  
    
    useEffect(() => {
      setMessage (state[0]['message']['data']['message']);
    },[state[0]['message']['data']['message']])
  
  
  return (
    <section style={loginWrapper}>
      <form action="" style={signin_form} onSubmit={handleSubmit}>
        <div style={signin_form_logo}>
          <img alt="logo" src="assets/images/logo.png" className="Login_logo" />
        </div>
        <div className="from-group mb-4">
          <div className="login-input-wrap ">
            <input
              id="email"
              type="email"
              name="email"
              className="form-control effect-8"
              placeholder="Enter Email"
              value={formvalues.email}
              onChange={handleChange}
            />
            <span className="focus-border">
              {" "}
              <i></i>
            </span>
          </div>
           {errors.email && <p style={error}>{errors.email}</p>}
        </div>

        <div className="from-group mb-4">
          <div className="login-input-wrap ">
            <input
              id="password"
              type="password"
              name="password"
              className="form-control effect-8"
              placeholder="Enter password"
              value={formvalues.password}
              onChange={handleChange}
            />
            <span className="focus-border">
              {" "}
              <i></i>
            </span>
           
          </div>
           {errors.password && <p style={error}>{errors.password}</p>}
        </div>
        <div className="form-group">
          <button style={login_btn}>login</button>
        </div>
      </form>
         <If condition={isMessage}>
              <SuccessAlert msgclass={message} msg={message} setmsg={setIsMessage} alertClass={state[0]['message']['class']}/>
            </If> 
    </section>
  );
};
