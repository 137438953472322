import React from 'react'
import { useHistory } from 'react-router';


export const LoginManage = () => {
    const history = useHistory();
    React.useEffect(() => {
      let login = localStorage.getItem("login");
      if(login) {
       history.push("/dashboard");
      }
    }, [])  


}
