import React from 'react'
import { useHistory } from 'react-router';
import {FaBars} from 'react-icons/fa';

function Navbar() {
const history = useHistory();

const logoutHandler = () => {
  localStorage.clear();
  history.push("/");

}
  return (
        <React.Fragment>
        <nav className="navbar navbar-expand-lg main-navbar">
  
        <div className="user-title">
          <span   className="menu-btn"><FaBars/> </span>
          <h5>Hello,Admin</h5>
          
        </div>


    <button className="common-btn" onClick={logoutHandler}> logout </button>


  </nav>   
         </React.Fragment>
    )
}

export default Navbar
