import React ,{useContext, useEffect} from 'react'
import useForm from './useForm';
import validate from './validate';
import {SuccessAlert} from '../SuccessAlert';
import { If  ,Then } from 'react-if';
import {Context} from "../Store"


const AddUser = () => {

  
  const [isMessage , setIsMessage] = React.useState(false);
  const [message, setMessage] = React.useState()
  const [edit ,setEdit] = React.useState(true);
  const {handleChange,formvalues,handleSubmit,errors} = useForm(validate);
  const [state , dispatch] = useContext(Context);
   

  useEffect(() => {    
    setEdit(state.editUser.edit);
 
  }, [state.editUser.edit]); 

  useEffect(() => {
    setIsMessage(state['message']['Ismessage']);
  },[state['message']['Ismessage']])

  
  useEffect(() => {
    setMessage (state['message']['data']['message']);
  },[state['message']['data']['message']])

  const editState = () => {
    const editdata = {data:"" , edit:true}
    dispatch({type: 'EDIT_USER', payload:editdata});
  }


    return (
        <div>
            <div>
            <form  onSubmit={handleSubmit} className="add-user-form">
              <h6>{!edit?"Update User":"Add User"}</h6>
              <div className="add_user_form_wrap">
                
                <div className="add_user_form_input">
                  <div className="from-group">
                    <div className="login-input-wrap">
                    
                    <input 
                        type="hidden"
                        className="" 
                        id="id" 
                        name="id"
                        value={formvalues.id}
                         
                    />
                    <input 
                        type="text"
                        className="form-control effect-8" 
                        id="name" 
                        placeholder="Name"
                        name="name"
                        value={formvalues.name}
                        onChange={handleChange}
                        autoComplete="off"
                         
                    />
                      <span className="focus-border"> <i></i></span> 
                     
                    </div>
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>
                </div>
    
                <div className="add_user_form_input">
                  <div className="from-group">
                    <div className="login-input-wrap">
                      <input 
                        type="email"
                        className="form-control effect-8"
                        id="email"
                        placeholder="Email"                        
                        name="email"
                        value={formvalues.email}
                        onChange={handleChange}
                        autoComplete="off"
                         
                     />
                      <span className="focus-border"> <i></i></span> 
                    </div>
                  </div>
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>


                <If condition={edit}>
                <div className="add_user_form_input">
                  <div className="from-group">
                    <div className="login-input-wrap">
                      <input 
                        id="password"
                        type="password"
                        className="form-control effect-8 "
                        placeholder="password"
                        value={formvalues.password}
                        name="password"
                        onChange={handleChange}
                        autoComplete="off"
                       
                      />
                      <span className="focus-border"> <i></i></span> 
                      </div>
                      {errors.password && <p className="error">{errors.password}</p>}
                  </div>
                </div>
                </If>

                <If condition={edit}>
                <Then>                
                <div className="add_user_form_input">
                  <div className="from-group">
                    <div className="login-input-wrap">
                      <input 
                        id="cfnpassword"
                        type="password"
                        className="form-control effect-8"
                        placeholder="Confirm Password"
                        value={formvalues.cfnpassword}
                        name="cfnpassword"
                        onChange={handleChange}
                        autoComplete="off"
                       
                      />
                      <span className="focus-border"> <i></i></span> 
                      {errors.cfnpassword && <p className="error">{errors.cfnpassword}</p>}
                    </div>
                    
                  </div>
                </div>
                </Then>
             
                </If>
    
                <div className="common-btn_input">
                  <div className="from-group">
                  
                    <input type="submit" value={!edit?"Update":"Submit"} className="common-btn"/>
                  </div>
                </div>
                <If condition={!edit}>
                 <div className="common-btn_input">
                     <button className="common-btn btn-danger" 
                      onClick={()=>{
                        setEdit(true)
                        editState()  
                        }}
                      >Cancel</button>
                  </div>
                </If>
              </div>
            </form>
            <If condition={isMessage}>
              <SuccessAlert msgclass={message} msg={message} setmsg={setIsMessage} alertClass={state['message']['class']}/>
            </If> 
            </div>
        </div>
    )
}

export default AddUser
