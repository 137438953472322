// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


// const toastSuccess = () => {
//     toast.success("Login Successful");
// }

// export {toastSuccess};


import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function successToast (msg){
  toast.success(msg);  
}

export function errorToast (msg){
  toast.error(msg);  
}



