import React from 'react'

const DashboardBox = (props) => {
    return (
        <React.Fragment>
        <div className="col-lg-4 col-md-6">
            <div className="card-outter">
                <div className="heading">
                    <h5>{props.period}</h5>
                    <h1 className="count" data-count={props.total}>{props.total}</h1>
                </div>
                <span><i className="fas fa-quote-right"></i></span>
            </div>
        </div>
         </React.Fragment>
    )
}

export default DashboardBox
