import React,{useEffect}from 'react'
import { useHistory } from 'react-router';

const DashboardManage = () => {
  const history = useHistory();
    useEffect(() => {
      let login = localStorage.getItem("login");
      if(!login) {
       history.push("/login");
      }
    }, [])  
}

export default DashboardManage
