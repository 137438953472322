import React from 'react';
import { Link , NavLink } from 'react-router-dom';
import {FaUserCog,FaAd,FaTachometerAlt,FaWpforms} from 'react-icons/fa';
import {} from 'react-icons/fa';


const Sidebar = () => {
    return (
         <React.Fragment>
        <div className="main-sidebar">
        <aside id="sidebar-wrapper">
              <div className="sidebar-brand">
                <Link to="/dashboard" htmlFor="logo"> <img alt="logo" src="assets/images/logo.png" className="header-logo"/></Link> 
              </div>
              
              <ul className="sidebar-menu">
                <li className="menu-header">Main</li>
                <li className="dropdown">
                  <NavLink activeClassName="active" to="/dashboard" className="nav-link"><FaTachometerAlt/><span>Dashboard</span></NavLink>
                </li>
                
                 <li className="dropdown">
                  <NavLink activeClassName="active" to="/manageuser" className="nav-link"><FaUserCog/><span>Manage User</span></NavLink>
                </li>
     
                 <li className="dropdown">
                  <NavLink activeClassName="active" to="/advtBanner" className="nav-link"><FaAd/><span>Advertise Banner</span></NavLink>
                </li>

                <li className="dropdown">
                  <NavLink activeClassName="active" to="/UpdatedPlayerRank" className="nav-link"><FaWpforms/><span>Updated Player Rank</span></NavLink>
                </li>  
              </ul>
         </aside>
      </div>   
         </React.Fragment>
    )
}

export default Sidebar
