const validateInfo = (values) => {
    let errors = {}
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!values.email){
        errors.email = "Email Required"
    } 
    else if(!(re.test(values.email))){
        errors.email = "Invalid Email ";
    }
    
    if(!values.password){
        errors.password = "Password Required"
    }
    // else if (values.password.length < 6){
    //     errors.password = "Password Less Than 6 character";
    // }

    return errors
}

export default validateInfo
