import React,{useState,useEffect,useContext} from 'react';
import MaterialTable from 'material-table';
import axios from "axios";
import {Context} from "../Store";
import { useHistory } from 'react-router';
import {APP_ENDPOINTS} from "../../Utility";


const Table = () => {
  // const state = useContext(Context);
  const [state, dispatch] = useContext(Context);
  const [tableData ,setTableData] = useState([ ])
  const user = state['user']['status'];
  const [loader , setLoader] = useState(true)
  const history = useHistory();


  useEffect(() => {
    if(state.user){
      getUser();
   }
  }, [state.user]); 


  useEffect(() => {
    if(state.updateUser){
      getUser();
      dispatch({type: 'UPDATE_USER', payload: false});
      const editdata = {"data":'' , edit:false}
    dispatch({type: 'EDIT_USER', payload:editdata});
   }
  }, [state.updateUser]); 

  const  editHandler = (data) => {
    const editdata = {data , edit:false}
    dispatch({type: 'EDIT_USER', payload:editdata});
   }  

   const  deleteHandler = async (data) => {
   
    let token = localStorage.getItem("token");
    let sendheaders = {
      headers: {
          Authorization: 'Bearer ' + token,
      }
    }
    console.log(sendheaders);
    //console.log(APP_ENDPOINTS.DELETE_USER+"/"+data.id)
    try {
      const response = await axios.get(APP_ENDPOINTS.DELETE_USER+"/"+data.id,sendheaders )
      .then(function(response){
          if(response.status === 200){
          const dataDelete = [...tableData];
          const position = dataDelete.indexOf(data);
          dataDelete.splice(position, 1);
          setTableData(dataDelete);
          getUser();
       }
    })
      return response;  
    }catch (e) {
      console.log(e)
     
    } 

   }  

  const  getUser = async ()=>{
    let token = localStorage.getItem("token");
    let sendheaders = {
      headers: {
          Authorization: 'Bearer ' + token,
      }
    }

    try {
      const response = await axios.get(APP_ENDPOINTS.USER_LIST, sendheaders)
      .then(function(response){
          if(response.status === 200){
          setTableData(response.data);
          setLoader(false);
      }
      
    })
      return response;  
    }catch (e) {
      console.log(e)
     
    } 

  }


  const subcribeHandler = (data) =>{
    const subdata = {data:data,subscriptions:true}
    dispatch({type: 'SUB', payload:subdata});
    history.push("/subcription");
  }

  useEffect(() => {
    getUser();
  }, [])

  const columns = [
    { title:"id",field:"id",align:"center",width:'10%'},
    { title:"username",field:"username",align:"left",width:'15%'},
    { title:"email",field:"email",align:"left",width:'10%'},
  
  ]  

  const style ={
    position: "absolute ",
    top: "0 ",
    left: "0 ",
    background: "rgb(0 0 0 / 34%) ",
    width: "100% ",
    height: "100% ",
    zIndex: "22 ",
    display: "flex ",
    alignItems: "center ",
    justifyContent: "center ",
  }


    return (
         <React.Fragment>
      
        <MaterialTable  
        columns={columns} 
        data={tableData}
        isLoading={loader}
        // rowsPerPageOptions={[5]}
        onRowsPerPageChange={[5,10]}

    
        
        actions={[
          {
            icon: ()=>
            
            <div className="btn btn-action bg-purple mr-1" >Subscription</div>,
            tooltip: 'Subscription',
            onClick: (event, data) =>{subcribeHandler(data)}
          },
          {
            icon: ()=><div className="btn btn-action bg-purple mr-1">edit</div>,
            tooltip: 'Edit User',
            onClick: (event, data) =>{ editHandler(data)}
          },
          {
            icon: ()=><div className="btn btn-danger  mr-1">Delete</div>,
            tooltip: 'Delete User',
            onClick: (event, data) =>{console.log(event); deleteHandler(data)}
          }
        ]}

        
        options={{
          pageSize: 10,
          searchFieldVariant:"outlined", 
          pageSizeOption:[5,10,15,20],
          toolbar: true,
          paging: true,
          paginationType:"stepped",
          showFirstLastButtons:true,
          actionsColumnIndex:-1,

            }}
        title=""/> 
        
        
            

         </React.Fragment>
    )
}


export default Table;