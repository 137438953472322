const BASE_URL = "https://u.fanalyze.com";
// const BASE_URL = "http://23.21.68.255:3000";
let token = localStorage.getItem("token");
export default {
    TOKEN : {token},
    LOGIN: `${BASE_URL}/auth/admin_login`,
    SIGN_UP: `${BASE_URL}/user/register`,
    UPDATE_REGISTER: `${BASE_URL}/user/updateByAdmin`,
    USER_LIST: `${BASE_URL}/user/list_all`,
    DELETE_USER: `${BASE_URL}/user/delete/`,
    DASHBOARD_DATA: `${BASE_URL}/user/getDashboardData`,
    CHECKSUBSCRIPTION : `${BASE_URL}/chargebee/checkSubscription`,
    ADD_SUBCRIPTION : `${BASE_URL}/chargebee/adminAddSubscription`,
    GET_BANNER : `${BASE_URL}/banner/`,
    IMG_BANNER : `${BASE_URL}/getImg/`,
    UPDATE_URL : `${BASE_URL}/banner/updateUrl`
  }