import React,{useContext,useEffect,useState} from 'react';
import DashboardBox from './DashboardBox';
import axios from "axios";
import APP_ENDPOINTS from '../../Utility/Constant';
import {Context} from "../Store"


const Dashboard = () => {

  const [state , dispatch] = useContext(Context);
  

  const [subYearly , setSubYearly] = useState();
  const [subMonthly , setSubMonthly] = useState();
  const [subDaily , setSubDaily] = useState()
  const [regYearly , setRegYearly] = useState();
  const [regMonthly , setRegMonthly] = useState();
  const [regDaily , setRegDaily] = useState()
    useEffect(() => {
      getDashboardData();   
      const msg = {"Ismessage":false ,"data": "",class:""}
      dispatch({type: 'USER_MESSAGE', payload: msg })
    }, [])


    const getDashboardData = async () => {

      let token = localStorage.getItem("token");
      let sendheaders = {
        headers: {
            Authorization: 'Bearer ' + token,
        }
      }
      try {
        const response = await axios.get(APP_ENDPOINTS.DASHBOARD_DATA, sendheaders)
        .then(function(response){
            if(response.status === 200){
              setSubDaily( response.data.SubTotalDaycount);
              setSubMonthly(response.data.SubTotalMonthcount);
              setSubYearly(response.data.SubTotalYearlycount);             
              setRegYearly(response.data.TotalYearlycount);              
              setRegMonthly(response.data.TotalMonthlycount); 
              setRegDaily(response.data.TotalDailycount);              
            }
        })
      }catch (e) {
        console.log(e)
       
    }
  }

    return (
         <React.Fragment>
        <section className="dashboard-report">
        <div className="card p-3">
            <div className="row">
             <div className="col-lg-12">
               <h1 className="page-title"> Subscribed users</h1>
             </div> 
       
            <DashboardBox period={'Yearly'} total={subYearly}/>
            <DashboardBox period={'Monthly'} total={subMonthly}/>
            <DashboardBox period={'Daily'} total={subDaily}/>
            </div>
        </div>
  
        <div className="card p-3">
       
        
            <div className="row">
             <div className="col-lg-12">
               <h1 className="page-title"> Register users</h1>
             </div> 
             <DashboardBox period={'Yearly'} total={regYearly}/>
             <DashboardBox period={'Monthly'} total={regMonthly}/>
             <DashboardBox period={'Daily'} total={regDaily}/>
        
            </div>
        </div>
  
      
      </section> 
         </React.Fragment>
    )
}

export default Dashboard
