import React,{useState,useEffect,useContext} from 'react';
import SubscribeBox from "./SubscriptionBox";
import {Context} from "../Store";
import axios from "axios";
import {APP_ENDPOINTS} from "../../Utility"

const Subscription = () => {


    
   // window.location.href = "/manageuser"

   const [state] = useContext(Context);
   const [userId , setUserId] = useState('');
   const [subscribedplan , setsubscribedplan] = useState([]);
	
   useEffect(() => {
      setUserId(state.sub.data.id);
      if(state.sub.data.id !== undefined){
         localStorage.setItem("userid" , state.sub.data.id)
      }
      if(state.sub.subscriptions){
      checkSubscription(state.sub.data.id);
     }

   },[state])

   useEffect(() => {
         checkSubscription(localStorage.getItem("userid"))
   },[])

   const checkisSubscribe = (sub) => {
      const data = subscribedplan.find(plan => {
         return plan[0] === sub
      });
      if(data === undefined){
         return true;
      }
      return data[1]
   }


   const checkSubscription = async (userId) =>{
     
    const  data = {user_id:userId}
        let token = localStorage.getItem("token");
        let sendheaders = {
          headers: {
              Authorization: 'Bearer ' + token,
          }
        }
        try {
            const response = await axios.post(APP_ENDPOINTS.CHECKSUBSCRIPTION, data , sendheaders)
            .then(function(response){
                if(response.status === 200)
                {
                    setsubscribedplan(response.data.subscribedPlans)
                }
            else{
                alert("fail");
                }
            })
        }
        catch (e) {
            console.log(e);
        } 
    }     
  



   return (
         <React.Fragment>
		<section className="plan-container">
            <div className="card">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="row">
                        <div className="col-lg-12">
                        <h2 className="plan-title">Draft cheat sheet</h2>
                     </div>
                     </div>
                     <div className="main-flex">
					 <SubscribeBox userId={userId} planId={"draft-cheat-sheet"} plan={"Yearly Subscription"} price={"$53.99"} user={userId} detail={"top source aggregated"} planSub={checkisSubscribe("draft-cheat-sheet") ? "upgrade" : "subscribed"} active={checkisSubscribe("draft-cheat-sheet") ? "" : "plan-active"}/>
                     </div>
                  </div>
                  
                  <div className="col-lg-12 mt-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="plan-title">fantasy data for all sports</h2>
                      </div>
                    </div>
                  
                     <div className="main-flex"> 
					 <SubscribeBox userId={userId} planId={"pro-all-sports"} plan={"Yearly Subscription"} price={"$53.99"} detail={"For 12 months fantasy sports data"} planSub={checkisSubscribe("pro-all-sports") ? "upgrade" : "subscribed"} active={checkisSubscribe("pro-all-sports") ? "" : "plan-active"}/>
					 <SubscribeBox userId={userId}  planId={"pro-all-sports-monthly"} plan={"Monthly  Subscription"} price={"$8.99"} detail={"per month for fantasy sports data"} planSub={checkisSubscribe("pro-all-sports-monthly") ? "upgrade" : "subscribed"} active={checkisSubscribe("pro-all-sports-monthly") ? "" : "plan-active"}/>
                     </div>
                   </div>

               <div className="col-lg-6 mt-4">
                  <div className="row ">
                     <div className="col-lg-12">
                        <h2 className="plan-title">sports betting and fantasy data for all sports</h2>
                     </div>
                  </div>   
                  <div className="main-flex">
               <SubscribeBox userId={userId} planId={"all-star-all-sports-yearly"} plan={"Yearly Subscription"}  price={"$174.99"} detail={"For 12 months Fantasy Data And Sports Betting data "} planSub={checkisSubscribe("all-star-all-sports-yearly") ? "upgrade" : "subscribed"} active={checkisSubscribe("all-star-all-sports-yearly") ? "" : "plan-active"}/>
               <SubscribeBox userId={userId} planId={"all-star-all-sports-monthly"} plan={"Monthly  Subscription"} price={"$28.99"} detail={"per month for Fantasy Data And Sports Betting data "} planSub={checkisSubscribe("all-star-all-sports-monthly") ? "upgrade" : "subscribed"} active={checkisSubscribe("all-star-all-sports-monthly") ? "" : "plan-active"}/>
                  </div>
               </div>

            </div>
         </div>
   </section>
		 </React.Fragment>
        )
}

export default Subscription;