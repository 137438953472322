import React,{ useState,useEffect,useContext} from "react";
import axios from "axios";
import {Context} from "../Store";
import {APP_ENDPOINTS} from "../../Utility"

// import const dispatch = useDispatch(function)

const useForm = (validate) => {
    const [state, dispatch] = useContext(Context);
    const [errors,setErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);
    const [resp , setResp] = React.useState();
    const [edit , setEdit] = useState(false);
    const [message , setMessage] = useState(false);
   

    useEffect(() => {
      let id = state.editUser.data.id;
      let email = state.editUser.data.email;
      let name = state.editUser.data.username; 
      let password = "123";
        console.log(email);
        if(state.editUser.edit !== true) {
            editUser(email,name,id);  
        }else{
            setValues ({
                id:'',
                name: '',
                email:'',
                password:"",
                cfnpassword:""
              }) 
        }
    
    }, [state]); 
    



    const [formvalues , setValues] = useState({
        id:"",
        name : "",
        email: "",
        password : "",
        cfnpassword:"",
    });
    const resetForm = () => {
          setValues ({
        id:"",
        name: "",
        email:"",
        password:"",
        cfnpassword:""
      })
    }
    const handleChange = (e)=>{
        const {name , value} = e.target;
        setValues({
            ...formvalues,
            [name]:value
        });
    };

    const editUser = (email,name,id) => {
        setEdit(true);
        setIsSubmit(false);
        setValues ({
        id:id,
        name: name,
        email:email,
        password:"123",
        cfnpassword:"123"
      })
     
    }

    const handleSubmit = e =>{
        e.preventDefault();
            setErrors(validate(formvalues));
            setIsSubmit(true);
            setMessage(false);
           
    };



    useEffect(() => {
        if(Object.keys(errors).length === 0 && isSubmit){
         
            if(state.editUser.edit === true){
                handleRegister(formvalues);
            }
            else{
                 editUserHandle(formvalues);
             }
        }    
    }, [errors])

    
    const editUserHandle = async (values) =>{
        let token = localStorage.getItem("token");
        let sendheaders = {
          headers: {
              Authorization: 'Bearer ' + token,
          }
        }   
 
        const data = {id:values.id,username:values.name,email:values.email}
        console.log(data);
        try {
            const response = await axios.post(APP_ENDPOINTS.UPDATE_REGISTER, data , sendheaders)
            .then(function(response){
                
                if(response.status === 200)
                {
                    const msg = {"Ismessage":true ,"data": response.data,class:"success"}
                    dispatch({type: 'USER_MESSAGE', payload: msg })
                    dispatch({type: 'UPDATE_USER', payload: true});
                    // dispatch({type: 'EDIT_USER', payload: true});
                    resetForm();
                }
            })
          }
          catch (e) {
            console.log(e.response);
            console.log(e.response.data.message);
            if(e.response.status === 500){
                if( e.response.data.message === "Error updating user with id undefined"){
                    const msg = {"Ismessage":true ,"data": {'message':"Email id Already Registered"},class:"danger"}
                    dispatch({type: 'USER_MESSAGE', payload: msg })
             }
           
          }     }  
    }

        
    const handleRegister = async (values) =>{
        let username = values.name;
        let email = values.email;
        let password = values.password;
        
        try {
            const response = await axios.post(APP_ENDPOINTS.SIGN_UP, {username, email, password})
            .then(function(response){
                setMessage(false)
                if(response.status === 200){
                    setResp(JSON.stringify(response.data));
                    dispatch({type: 'USER_REGISTER', payload: JSON.stringify(response.data)});
                    if(response.data.status === "success"){
                        const msg = {"Ismessage":true ,"data": response.data,class:"success"}
                        dispatch({type: 'USER_MESSAGE', payload: msg })
                        dispatch({type: 'USER_REGISTER', payload: true })
                        resetForm();
                        }
                    if(response.data.status === "failure"){
                        dispatch({type: 'USER_REGISTER', payload: JSON.stringify(response.data)});
                        const msg = {"Ismessage":true ,"data": response.data,class:"danger"}
                        dispatch({type: 'USER_MESSAGE', payload: msg })
                        setMessage(true);
                        resetForm();
                    }

                 }
            })
            return response;  
          }
          catch (e) {
            console.log(e)
            
          }       
    }

    return {handleChange,formvalues,handleSubmit,errors,message};

}

export default useForm