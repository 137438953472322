const Reducer = (state, action) => {
    switch (action.type) {
        case 'USER_REGISTER':
            return {
                ...state,
                user: action.payload
            };
        case 'USER_MESSAGE':
            return {
                ...state,
                message: action.payload
            };
        case 'SUB':
            return {
                ...state,
                sub: action.payload
            }; 
        case 'EDIT_USER':
        return {
            ...state,
            editUser: action.payload,
          
        };
        case 'UPDATE_USER':
        return {
            ...state,
            updateUser: action.payload, 
        };    
        case 'ADD_POST':
            return {
                ...state,
                isLogin: action.payload
            };
            
        case 'REMOVE_POST':
            return {
                ...state,
                posts: state.posts.filter(post => post.id !== action.payload)
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default Reducer;
