import {useContext, useState,useEffect} from "react";
import { useHistory } from 'react-router';
import axios from "axios";
import {Context} from "../Store";
import {APP_ENDPOINTS} from "../../Utility"


const useForm = (validate) => {
    const history = useHistory();
    const [errors,setErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [formvalues , setValues] = useState({email : "", password : ""});
    
    const handleChange = (e)=>{
        const {name , value} = e.target;
        setValues({
            ...formvalues,
            [name]:value
        });
    };

    const handleSubmit = e =>{
        e.preventDefault(); 
        setErrors(validate(formvalues));
        setIsSubmit(true);
       
    };

    useEffect(() => {
        if(Object.keys(errors).length === 0 && isSubmit){
            console.log(formvalues);
            handleLogin(formvalues);
        }
    }, [errors])

    const handleLogin = async (values) =>{
        let email = values.email;
        let password = values.password;
        try {
            // const response = await  axios.post('https://reqres.in/api/login', {
            const response = await axios.post(APP_ENDPOINTS.LOGIN, {email, password})
            .then(function(response){
                console.log(response);
                if(response.status === 200){
                let tokens = response.data.Authorization;
                localStorage.setItem("token" ,tokens)
                localStorage.setItem("login",true);   
                history.push("/dashboard");
            }else{
                localStorage.clear();
                localStorage.setItem("login",false);
            }
            })
            return response;  
          }
          catch (e) {
            console.log(e.response);
            alert("Internal server Error");
            console.log(state);
            setValues({email : "", password : ""});
            const msg = {"Ismessage":true ,"data": "Internal Server Error",class:"danger"}
            dispatch({type: 'USER_MESSAGE', payload: msg })
            // if(e.response.status === 400){
            //    const msg = {"Ismessage":true ,"data": e.response.data,class:"danger"}
            //    dispatch({type: 'USER_MESSAGE', payload: msg })
            // }
           
          }        
           
    }

    return {handleChange,formvalues,handleSubmit,errors};

}

export default useForm;