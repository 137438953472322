import React,{Fragment, useEffect, useState,useRef} from "react";
import MaterialTable from 'material-table';
import { useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import { SuccessAlert } from "../SuccessAlert";
import { errorToast, successToast } from "../toastify";
import { ToastContainer } from "react-toastify";

const UpdatedPlayerRank = (props) => {


  const [category,setCategory] = useState("nfl");
  const [type,setType] = useState("ss")
  const [searchedValue, setSearchedValue] = useState();
  const [searchPlayerList,setSearchPlayerList] = useState([]);
  const [showPlayerList,setShowPlayerList] = useState(false);
  const [selectedPlayer,setSelectedPlayer] = useState([]);
  const [loader , setLoader] = useState(false)
  const [tableData,setTableData] = useState([]);
  const [showForm , setShowForm] = useState(false);
  const [isEdit,setIsEdit] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  
  // useEffect(()=> {
  //   getAllData();
  // },[])

  useEffect(()=>{
    getAllData()
    setLoader(true)
    setShowForm(false);
    resetForm();
  },[type])
 
  

  const getAllData = () => {
    try {
      axios.post('https://u.fanalyze.com/scrap/selectSource',{
        type:type
      }).then(response=>{
        setTableData(response.data.data)
        setLoader(false)
      }).catch(errors => {
       errorToast(errors.message);
      })
    } catch (err) {
        console.log("err",err)
    }
  }



  const columns = [
    { title:"rank",field:"id",align:"center",width:'10%'},
    { title:"player",field:"name",align:"left",width:'15%'},
    { title:"Team",field:"team",align:"left",width:'15%'},
    { title:"Position",field:"position",align:"left",width:'15%'},
    { title:"week",field:"week",align:"left",width:'15%'},
    { title:"Type",field:"type",align:"left",width:'15%'},
    { title:"Web Name",field:"wbname",align:"left",width:'15%'},
    { title:"Web URL",field:"wburl",align:"left",width:'15%'},
    // { title:"email",field:"email",align:"left",width:'10%'},
  ]  

const validationSchema = Yup.object().shape({
    week:Yup.number().required("This field is required").min(1,"Minimum 1 week is required").max(18,"Maximum till 18 week is allowed"),
    team:Yup.string().required("This field is required"),
    playerName:Yup.string().required("This field is required"),
    playerType: Yup.string().oneOf(['start', 'sit'],'Invalid Type').required('This field is required'),
    position:Yup.string().oneOf(["QB", "RB", "WR", "TE", "K", "DE"] , "Invalid Position").required("This field is required"),
    wbname: Yup.string().required('Please enter website'),
    wburl: Yup.string().required('Please enter url'),
 
});


const waiverWirevalidationSchema = Yup.object().shape({
  week:Yup.number().required("This field is required").min(1,"Minimum 1 week is required").max(18,"Maximum till 18 week is allowed"),
  team:Yup.string().required("This field is required"),
  playerName:Yup.string().required("This field is required"),
  position:Yup.string().oneOf(["QB", "RB", "WR", "TE", "K", "DE"] , "Invalid Position").required("This field is required"),
  wbname: Yup.string().required('Please enter website'),
  wburl: Yup.string().required('Please enter url'),

});


const RankvalidationSchema = Yup.object().shape({
  rank:Yup.number().required("This field is required"),
  team:Yup.string().required("This field is required"),
  playerName:Yup.string().required("This field is required"),
  // playerType: Yup.string()
  // .oneOf(
  //   ['start', 'sit',],
  //   'Invalid Type'
  // )
  // .required('This field is required'),
  position:Yup.string().oneOf(["QB", "RB", "WR", "TE", "K", "DE"] , "Invalid Position").required("This field is required"),
  bye:Yup.number().required("This field is required"),
  wbname: Yup.string().required('Please enter website'),
  wburl: Yup.string().required('Please enter url'),
});


const   initialValues =
type === "rank" ? 
{
   
    rank:"",
    playerName:"",
    team:"",
    playerData:"",
    playerType:"",
    //type:"",
    position:"",
    bye:"",
    wbname: "",
    wburl: "",
}:
type === "ss"?
{
  week:"",
  playerName:"",
  team:"",
  playerData:"",
  playerType:"",
  //type:"",
  position:"",
  wbname: "",
  wburl: "",
}:
{
  week:"",
  playerName:"",
  team:"",
  playerData:"",
  //playerType:"",
  //type:"",
  position:"",
  wbname: "",
  wburl: "",
}
;


const {
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    touched,
    values,
    setValues,
    resetForm
  } = useFormik({
    initialValues ,
    validationSchema: type === "rank" ? RankvalidationSchema : type === "ss" ?validationSchema: waiverWirevalidationSchema,
    onSubmit: () => {
     let data = {...values,type :type}
     addPlayerHandler(data);
     
    },
  });

// const handleSubmit = (values) => {
// //dispatch(registerAsync(values));
// console.log("values",values);
// let apiUrl = "";
// try {
//     axios.post(apiUrl, values)
//       .then(response => {
//         // Handle the successful response here
//         console.log('Response data:', response.data);
//       })
//       .catch(error => {
//         // Handle any errors that may occur during the request
//         console.error('Error:', error);
//       });
//   } catch (error) {
//     console.error('An error occurred:', error);
//   }
// };


const getPlayerHanlder = async(e) => {
  if (e.target.value.length > 3){
    setSearchedValue(e.target.value);
    try {
        axios.get('https://playerapi.fanalyze.com/v1/nfl/players/list?prefix='+ e.target.value)
          .then(response => {
            // Handle the successful response here
            setSearchPlayerList(response.data.response)
            setShowPlayerList(true)
          })
          .catch(error => {
            // Handle any errors that may occur during the request
            console.error('Error:', error);
          });
      } catch (error) {
        setSearchPlayerList([])
        setShowPlayerList(false)
        console.error( error);
      }
    }else{
      setSearchPlayerList([])
      setShowPlayerList(false)

    }
  };


const addPlayerHandler = (values) => {
  if(isEdit){
    try {
      axios.post('https://u.fanalyze.com/scrap/updateSource',values).then(response=>{
        successToast("Your record has updated");  
        getAllData();
        setShowForm(false);
        resetForm();
      }).catch(errors => {
      errorToast(errors.message);
      })
    } catch (err) {
        console.log("err",err)
    }
  }else{
    try {
      axios.post('https://u.fanalyze.com/scrap/insertSource',values).then(response=>{
        successToast("Your record has inserted");  
        getAllData()
        setShowForm(false)
        resetForm();
      }).catch(errors => {
      errorToast(errors.message);
      })
    } catch (err) {
        console.log("err",err)
    }
  }
}  
    

const playerEditHandler = (data) => {
  setValues({...values,
  id:data.id,
  week:data.week,
  rank:"",
  playerName:data.name,
  team:data.team,
  playerData:"",
  playerType: data.type !== undefined && (data.type).toLowerCase(),
  position:data.position,
  bye:data.bye,
  wbname: data.wbname,
  wburl: data.wburl,
})
  setIsEdit(true);
  setShowForm(true);
  
}

const deletePlayerHandler = (id) => {
  let data = {
    id,
    type 
  }
  try {
    axios.post('https://u.fanalyze.com/scrap/deleteSource',data).then(response=>{
      successToast("Your record has updated");  
      getAllData()
    }).catch(errors => {
    errorToast(errors.message);
    })
  } catch (err) {
      console.log("err",err)
  }
}

return(
  <Fragment>
    <div className="rank-update-wrapper">
        {/* <div className="categoryType">
            <span onClick={()=>setCategory("nfl")} className={category === "nfl" ? "active":""}>
                NFL
            </span>
            <span onClick={()=>setCategory("mlb")} className={category === "mlb" ? "active":""} >
                MLB
            </span>
            <span onClick={()=>setCategory("nba")} className={category === "nba" ? "active":""}>
                NBA
            </span>
        </div> */}

        <div className="categoryType" ref={ref} >
            {/* <span onClick={()=>setType("rank")} className={type === "rank" ? "active":""}>
                Ranking
            </span> */}
            <span onClick={()=>setType("ss")} className={type === "ss" ? "active":""}>
                start sit
            </span>
       
            <span onClick={()=>setType("wv")} className={type === "wv" ? "active":""}>
                WaiverWire
            </span>
        </div>


        {showForm && 
          <div className="form-wrapper">
              <form  onSubmit={handleSubmit} className="add-player-wrapper">
                <div className="row">

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label>Week</label>
                    <input className={`form-control ${focusedInput === 'week' ? 'focused' : ''}`} type="text" id="week" name="week" onChange={handleChange}  value={values.week}/>
                    {errors.week && touched.week && (
                      <span className='text-danger'>{errors.week}</span>
                    )}
                  </div>
                </div>

                 {type === "rank" &&     
                  <div className="col-lg-6">
                    <div className="mb-3"> 
                      <label>Rank</label>
                      <input className="form-control" type="number" id="rank" name="rank" onChange={handleChange}  value={values.rank}/>
                      {errors.rank && touched.rank && (
                        <span className='text-danger'>{errors.rank}</span>
                      )}
                    </div>
                  </div>
                }


                <div className="col-lg-6">
                  <div className="position-relative mb-3">
                    <label>Player</label>
                    <input className="form-control" type="text" id="playerName" value={values.playerName} name="playerName" onChange={(e)=>{getPlayerHanlder(e)
                    ;handleChange(e)
                    }}/>
                    {errors.playerName && touched.playerName && (
                                <span className='text-danger'>{errors.playerName}</span>
                    )} 

                    <ul className={showPlayerList ? "playerlist-wrap flex" :"playerlist-wrap d-none"}>
                      {
                      searchPlayerList.map((data,index)=>(
                        <li key={index} onClick={()=>{setValues({...values,playerName:data.name,playerData:data});setShowPlayerList(false);setSearchPlayerList([])} }>
                        <span>
                          <img src={data.photo_url}></img>
                        </span>
                        <h6>{data.name}</h6>
                      </li>
                      ))
                      }
                    </ul>
                  </div>   
                </div>   

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label>Team</label>
                    <input className="form-control" type="text" id="team" name="team" onChange={handleChange}  value={values.team}/>
                    {errors.team && touched.team && (
                      <span className='text-danger'>{errors.team}</span>
                    )}
                  </div>
                </div>


               {
                type === "ss" &&
                <div className="col-lg-6">
                  <div>
                    <label>Type</label>
                      <select className="form-control" id="playerType" name="playerType"  value={values.playerType} onChange={handleChange}>
                          <option>select type</option>
                          <option value="start">start</option>
                          <option value="sit">sit</option>
                      </select>    
                      {errors.playerType && touched.playerType && (
                                  <span className='text-danger'>{errors.playerType}</span>
                      )}      
                  </div>
                </div> 
                }       


                <div className="col-lg-6">
                  <div className="mb--3"> 
                    <label>position</label>
                    <select className="form-control" id="position" name="position"  value={values.position} onChange={handleChange}>
                          <option>select position</option>
                          <option value="QB">QB</option>
                          <option value="RB">RB</option>
                          <option value="WR">WR</option>
                          <option value="TE">TE</option>
                          <option value="K">K</option>
                          <option value="DE">DE</option>
                      </select>    
                      {errors.position && touched.position && (
                                  <span className='text-danger'>{errors.position}</span>
                      )}   
                  </div>  
                </div>       

                {type === "rank" &&
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label>Bye</label>
                    <input className="form-control" type="text" id="bye" name="bye" onChange={handleChange}  value={values.bye}/>
                    {errors.bye && touched.bye && (
                      <span className='text-danger'>{errors.bye}</span>
                    )}
                  </div>
                </div>
                }

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label>Website</label>
                    <input className="form-control" type="text" id="wbname" name="wbname" onChange={handleChange}  value={values.wbname}/>
                    {errors.wbname && touched.wbname && (
                      <span className='text-danger'>{errors.wbname}</span>
                    )}
                  </div>
                </div>        



                <div className="col-lg-6">
                  <div className="mb-3">
                    <label>Source URL</label>
                    <input className="form-control" type="text" id="wburl" name="wburl" onChange={handleChange}  value={values.wburl}/>
                    {errors.wburl && touched.wburl && (
                      <span className='text-danger'>{errors.wburl}</span>
                    )}
                  </div>
                </div>     
                </div>
                <button type='submit' className="common-btn"> Submit</button>  
                <button className="ml-2 common-btn btn-danger" onClick={()=>setShowForm(false)}>Cancel</button>         
              </form>  
          </div>
        }

   
              <div className="table-wrapper">

              <button className="common-btn" onClick={()=>{setShowForm(true);setIsEdit(false);resetForm()}}> Add Player</button>

              <MaterialTable  
                columns={columns} 
                data={tableData}
                isLoading={loader}
                // rowsPerPageOptions={[5]}
                onRowsPerPageChange={[5,10]}

    
        
        actions={[
          {
            icon: ()=><div className="btn btn-action bg-purple mr-1">edit</div>,
            tooltip: 'Edit Player',
            onClick: (event, data) =>{playerEditHandler(data);handleClick()}
          },
          {
            icon: ()=><div className="btn btn-danger  mr-1">Delete</div>,
            tooltip: 'Delete player',
            onClick: (event, data) =>{deletePlayerHandler(data.id);}
          }
        ]}

        
        options={{
          pageSize: 10,
          searchFieldVariant:"outlined", 
          pageSizeOption:[5,10,15,20],
          toolbar: true,
          paging: true,
          paginationType:"stepped",
          showFirstLastButtons:true,
          actionsColumnIndex:-1,

            }}
        title=""/> 


        </div>

    </div>
    <ToastContainer autoClose={2000} />
    
    </Fragment>
)
}
export default UpdatedPlayerRank