import React,{useEffect} from 'react';
import axios from "axios";
// import {Context} from "../Store";
import {APP_ENDPOINTS} from "../../Utility";

const SubscribeBox = (props) => {
    // const [state, dispatch] = useContext(Context);
    let userId = "";
     useEffect(() => {
    },[])
         const adminAddSubscription = async (planId) =>{
         userId  = localStorage.getItem("userid"); 
         const data = {user_id:userId,plan_id:planId}
         let token = localStorage.getItem("token");
         let sendheaders = {
           headers: {
               Authorization: 'Bearer ' + token,
           }
         }   
            try {
                const response = await axios.post(APP_ENDPOINTS.ADD_SUBCRIPTION, data , sendheaders)
               .then(function(response){
                    if(response.status === 200)
                    {
                        console.log(response);
                        window.location.href ="/subcription"
                        
                    }
               else{
                     alert("fail");
                   }
               })
             }
             catch (e) {
               console.log(e);
             } 
         }   

		return (
         <React.Fragment>
               
            <div className="plan-box">
                <div className= {`sub-plan  ${props.active}`}>
                    <h6>{props.plan}</h6>
                    <h3>{props.price}</h3>
                    <p>{props.detail}</p>
                    <button  className="common-btn" onClick={()=>adminAddSubscription( props.planId)}>{props.planSub} </button>
                </div>
            </div>
                   
         </React.Fragment>
        )
}

export default SubscribeBox;