import React from "react";
import axios from "axios";
import { APP_ENDPOINTS } from "../../Utility";

const AdvtBanners = () => {
  const [BannerImage, setBannerImage] = React.useState();
  const [BannerUrl, setBannerUrl] = React.useState();
  const [oddBannerImage, setoddBannerImage] = React.useState();
  const [oddBannerUrl, setoddBannerUrl] = React.useState();
  const [error, setError] = React.useState();
  const [odderror, setoddError] = React.useState();
  const [homeBanner, setHomebanner] = React.useState();
  const [oddScreenAad, setoddScreenAd] = React.useState();
  const [loader, setloader] = React.useState(false);
  const draftRef = React.useRef(null);
  const oddraftRef = React.useRef(null);

  React.useEffect(() => {
    getBanner();
  }, []);

  const homeImageHandler = (e) => {
    setBannerImage(e.target.files[0]);
    setError("");
  };

  const imageFileHandler = (e) => {
    setoddBannerImage(e.target.files[0]);
    setoddError("");
  };

  // const urlHandler = (e) => {
  //   console.log(e.target.value);
  // }

  let token = localStorage.getItem("token");
  let sendheaders = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const getBanner = async () => {
    try {
      const response = await axios
        .get(APP_ENDPOINTS.GET_BANNER, sendheaders)
        .then(function (response) {
          if (response.status === 200) {
            setHomebanner(response.data.allData.odddraftkings[0].image);
            setoddScreenAd(response.data.allData.draftking[0].image);
            setoddBannerUrl(response.data.allData.draftking[0].url);
            setBannerUrl(response.data.allData.odddraftkings[0].url);
          }
        });
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const uploadImage = async (image, bannerType, url) => {
    const formData = new FormData();
    if (image === undefined) {
      // const urlData = {"bannertype" : bannertype,"url" : url}
      try {
        const response = await axios
          .post(APP_ENDPOINTS.UPDATE_URL, { url, bannerType }, sendheaders)
          .then(function (response) {
            if (response.status === 200) {
              getBanner();
              setoddError("");
              setError("");
              setloader(false);
            } else {
              setloader(false);
            }
          });
        return response;
      } catch (e) {
        setloader(false);
        console.log(e);
      }
    } else {
      console.log("imagedefined");
      formData.append("file", image);
      formData.append("url", url);
      try {
        const response = await axios
          .post(APP_ENDPOINTS.GET_BANNER + bannerType, formData, sendheaders)
          .then(function (response) {
            if (response.status === 200) {
              getBanner();
              setloader(false);
            }
          });
        return response;
      } catch (e) {
        console.log(e);
        setloader(false);
      }
    }
  };

  // const fileUploaderHandler = async (e) => {
  //   e.preventDefault();
  //   console.log(homeBanner);
  //   if(!homeBanner === undefined){
  //     alert(0);
  //   }
  //   // if (BannerImage === undefined) {
  //   //   setError("Please upload a Image");
  //   // }else
  //   if (BannerUrl === undefined) {
  //     setError("Please Add a URL");
  //   }else{
  //       let extension = BannerImage.name.split(".").pop();
  //       if (
  //           extension === "png" ||
  //           extension === "jpg" ||
  //           extension === "jpeg" ||
  //           extension === "gif"
  //         ) {
  //           let bannertype = "odddraftkings";
  //           uploadImage(BannerImage, BannerImage.name, bannertype ,BannerUrl);

  //         } else {
  //           setError("Please upload png , jpg , jpeg or gif");
  //         }
  //   }
  // };

  const fileUploaderHandler = async (e) => {
    e.preventDefault();
    setloader(true);
    let homeBanner = draftRef.current?.files[0];

    if (homeBanner === undefined) {
      setError("Please Add Image");
      setloader(false);
    } else {
      if (BannerUrl === undefined || BannerUrl === "") {
        setError("Please Add a URL");
        setloader(false);
      } else {
        let bannertype = "odddraftkings";
        uploadImage(BannerImage, bannertype, BannerUrl);
      }
    }
    // let bannertype = "odddraftkings";
    // uploadImage(BannerImage, bannertype, BannerUrl);
  };

  const oddsUploaderHandler = async (e) => {
    e.preventDefault();
    let oddScreenAad = oddraftRef.current?.files[0];
    if (oddScreenAad === undefined) {
      setoddError("Please Add Image");
      setloader(false);
    } else {
      if (oddBannerUrl === undefined || oddBannerUrl === "") {
        setoddError("Please Add a URL");
        setloader(false);
      } else {
        let bannertype = "draftking";
        uploadImage(oddBannerImage, bannertype, oddBannerUrl);
      }
    }
    // let bannertype = "draftking";
    // uploadImage(oddBannerImage, bannertype, oddBannerUrl);
  };

  // const oddsUploaderHandler = async (e) => {
  //   e.preventDefault();
  //   console.log(oddBannerUrl);
  //   if (oddBannerImage === undefined) {
  //     setoddError("Please upload a Image");
  //   }else if (oddBannerUrl === undefined) {
  //     setoddError("Please Add a URL");
  //   }
  //    else {
  //       let extension = oddBannerImage.name.split(".").pop();
  //       if(  extension === "png" ||
  //       extension === "jpg" ||
  //       extension === "jpeg" ||
  //       extension === "gif"){
  //           let bannertype = "draftking";
  //           uploadImage(oddBannerImage, oddBannerImage.name, bannertype,oddBannerUrl) ;
  //       }else{
  //           setoddError("Please upload png , jpg , jpeg or gif");
  //       }

  //   }
  // };

  return (
    <section>
      <div className="row">
        <div className="col-12 col-sm-12 col-lg-12">
          <div className="card">
            <div className="card-header w-add-btn">
              <h4>Advertise Banner</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5 mb-3">
                  <div className="mb-3">
                    <div>
                      <label
                        htmlFor="formFile"
                        className="form-label  banner-label"
                      >
                        {" "}
                        Banner{" "}
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={homeImageHandler}
                        ref={draftRef}
                      />
                    </div>
                    <div class="mt-3">
                      <label
                        htmlFor="formFile"
                        className="form-label banner-label"
                      >
                        Banner URL
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="bannerUrl"
                        value={BannerUrl}
                        onChange={(e) => setBannerUrl(e.target.value)}
                      />
                      <p className="error">{error}</p>
                    </div>

                    <button
                      className={
                        loader ? "common-btn submit-btn-loading" : "common-btn"
                      }
                      onClick={fileUploaderHandler}
                    >
                      {loader ? (
                        <img
                          src="assets/images/spinner.gif"
                          style={{ width: "20px" }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 mb-3">
                  <div className="banner_wrapper draftbanner">
                    <img
                      alt="homeBanner"
                      src={APP_ENDPOINTS.IMG_BANNER + homeBanner}
                    />
                  </div>
                </div>

                <div className="col-lg-5 mb-3">
                  <div className="mb-3">
                    <div className="mt-3">
                      <label
                        htmlFor="formFile"
                        className="form-label banner-label"
                      >
                        Odds Banner
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={imageFileHandler}
                        ref={oddraftRef}
                      />
                    </div>
                    <div className="mt-3">
                      <label
                        htmlFor="formFile"
                        className="form-label banner-label"
                      >
                        Odds Banner URL
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="oddUrl"
                        value={oddBannerUrl}
                        onChange={(e) => setoddBannerUrl(e.target.value)}
                      />
                      <p className="error">{odderror}</p>
                    </div>
                    <button
                      className={
                        loader ? "common-btn submit-btn-loading" : "common-btn"
                      }
                      onClick={oddsUploaderHandler}
                    >
                      {loader ? (
                        <img
                          src="assets/images/spinner.gif"
                          style={{ width: "20px" }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 mb-3">
                  <div className="banner_wrapper oddsbanner">
                    <img
                      alt="oddsbanner"
                      src={APP_ENDPOINTS.IMG_BANNER + oddScreenAad}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvtBanners;
