import React,{useContext} from "react";
import { If } from 'react-if';
import {Context} from "./Store";

export const SuccessAlert = (props) => {

  const [show, setShow] = React.useState(true)
  const [state, dispatch] = useContext(Context);

  console.log('msgggg', props.msg)

  React.useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
       props.setmsg(false)
       const msg = {"Ismessage":false ,"data": "",class:""}
                        dispatch({type: 'USER_MESSAGE', payload: msg })
        }, 3000)
    return () => {
      clearTimeout(timeId)
      }

     }, []);
    return (
         <React.Fragment>
          
            {/* <div style={{position:'absolute',top:'50px',left:'0px',right:'0px',maxWidth:'750px',margin:'0 auto',zIndex:555}} className={"alert alert-"+props.msgclass} role="alert">
                hello
            </div> */}
            <If condition={props.msg !== undefined}>
              <div  style={{position:'absolute',top:'50px',left:'0px',right:'0px',maxWidth:'750px',margin:'0 auto',zIndex:555}} className={"alert alert-"+props.alertClass} role="alert">
                {props.msg}
              </div>
            </If>
         
        </React.Fragment>
    )
}

