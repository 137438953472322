const validate = (values) => {
    
    let errors = {}
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!values.name){
        errors.name = "Name Required"
     }
    //else if (values.name === letters){
    //     errors.name = "Only Character are allowed"
    // }

    if(!values.email){
        errors.email = "Email Required"
    }
    else if(!re.test(values.email)){
        errors.email = "Invalid Email";
    }

    if(!values.password){
        errors.password = "Password Required"
    }
    // else if (values.cfnpassword.length < 6){
    //     errors.cfnpassword = "Password lenght Must be more than 6 character";
    // }else if (values.cfnpassword.length > 8){
    //     errors.cfnpassword = "Password lenght Must be less than 8 character";
    // }

    if(!values.cfnpassword){
        errors.cfnpassword = "Confirm Password Required"
    }
    else if (values.cfnpassword !== values.password){
        errors.cfnpassword = "Confirm password does not match with Password";
    }

    return errors
}

export default validate
