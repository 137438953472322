// import CheckLogin from '../CheckLogin';
import * as React from 'react'
import { Route,} from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import Navbar from '../Components/Navbar';
 import DashboardManage from '../Utility/DashboardManage' 



const DashboardLayout = ({ children, ...rest }) => {  
  return (
  <>
    <div className="main">
    <Sidebar/>
    <Navbar/>
    <div className="main-content">
    {children}
    </div>
  </div>
 </>
    );
};

const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
  DashboardManage();
  return (
    <Route
      {...rest}
      render={(props) => (
        <DashboardLayout>
          <Component {...props} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutRoute;
