import React from 'react';
import { Link} from 'react-router-dom';


const NotFound = () => {

    return (
        <div className="opps-center">
            <div>
                <img alt="not_Found" src="assets/images/opps.png"></img>
            </div>
            <Link to="/dashboard" className="common-btn" >Go Back</Link>
        </div>
    )
}

export default NotFound
