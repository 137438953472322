import React, {createContext, useReducer} from "react";
import Reducer from './Reducer'


const initialState = {
    user:false,
    sub:{data:{},subscriptions:false},
    editUser:{data:{}, edit:true},
    message:{Ismessage:false,data:''},
    updateUser:false,
    isLogin: false,
};

export const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);