import React from 'react';  
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';  
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
/** Layouts **/  
import LoginLayoutRoute from "./layouts/LoginLayoutRoute";  
import DashboardLayoutRoute from "./layouts/DashboardLayout";  
/** Components **/    

import {Login} from './Components/Login/Login';
import Dashboard from './Components/Dashboard/Dashboard';  
import Manageuser from './Components/Manageuser/Manageuser';
import Subscription from './Components/Subcription/Subscription';
import NotFound from './Components/NotFound';
import {Store} from './Components/Store';
import AdvtBanners from './Components/AdvtBanners/AdvtBanners';
import  UpdatedPlayerRank  from './Components/UpdatedPlayer/UpdatedPlayerRank';

/*  App   */  
const App = () => {

  return (
    <React.Fragment>
    <Store>
    <Router>  
    <Switch> 
      <Route exact path="/"><Redirect to="/Login" /></Route>  
      <LoginLayoutRoute exact path="/login" component={Login} />  
      <DashboardLayoutRoute exact path="/dashboard" component={Dashboard} />  
      <DashboardLayoutRoute exact path="/manageuser" component={Manageuser} /> 
      <DashboardLayoutRoute exact path="/subcription" component={Subscription} />  
      <DashboardLayoutRoute exact path="/advtBanner" component={AdvtBanners} />  
      <DashboardLayoutRoute exact path="/UpdatedPlayerRank" component={UpdatedPlayerRank} />  
  
      <Route component={NotFound}/>
      </Switch>    
  </Router>  
  </Store> 
   </React.Fragment>
  )
}

export default App

