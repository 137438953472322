import React from 'react'
import AddUser from './AddUser'
import Table from "./Table";


const Manageuser = () => {
  return (
         <React.Fragment>
    <section>
    <div className="row">
    <div className="col-12 col-sm-12 col-lg-12">
      <div className="card">
        <div className="card-header w-add-btn">
          <h4>User  Details</h4>
        </div>
        <div className="card-body">
          <div className="mb-5">
            <AddUser/>
          </div>
        
        
      
    <div className="col-lg-12">
      <div className="table_wrapper">
      {/* <img src="assets/images/spinning-loading.gif" className='spinner'/>

        {/* <div className="filter">
              <select className="form-select" name='filter' aria-label="Default select example">
                <option  value="subcribe">All</option>   
                <option value="subcribe">subcribed</option>
                <option value="unsubcribe">unsubcribed</option>
            </select>
          </div> */}
        <Table/>
      </div>
  </div>
      </div>
      </div>
    </div>
  </div>
        </section>
         </React.Fragment>
    )
}

export default Manageuser
